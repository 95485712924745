export default class ReconnectTimeoutStatus {
  #statusCode = 599;

  statusCode() {
    return this.#statusCode;
  }

  toString() {
    return 'Custom reconnect timeout';
  }
}
