import {
  ADMIT_DELAY_FACTOR_MS,
  HOST_ADMIN_ACTIONS,
  HOST_SETTINGS,
  JOIN_REQUEST_STATUSES,
  SESSION_EVENT_TYPE_BY_STATUS,
} from 'frontend/constants/session';
import {
  CLIENT_ORIGIN,
  ROOM_CHANNEL_MESSAGE_TYPES,
  SESSION_EVENTS_TYPES,
} from 'frontend/constants';
import { action, computed, set, setProperties } from '@ember/object';
import { isEmberTesting } from 'ember-simplepractice/utils/is-testing';
import { not, notEmpty, reads } from '@ember/object/computed';
import { task, timeout, waitForProperty } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { waitForLater } from 'ember-simplepractice/utils/waiters';
import Service, { service } from '@ember/service';
import classic from 'ember-classic-decorator';
import moment from 'moment-timezone';

@classic
export default class SessionService extends Service {
  @service('twilio/remote-tracks') remoteTracks;
  @service persistentProperties;
  @service floatingUiElements;
  @service roomChannel;
  @service appointmentSettings;
  @service mixpanel;
  @service soundNotification;
  @service remoteLogger;

  participants = [];
  joinRequestSoundPlayed = false;
  firstParticipantJoinTime;
  maxParticipantsOnCall = 0;

  @tracked joinRequests = [];
  @tracked inWaitingRoom = false;
  @tracked roomModel;
  @tracked joinRequestDeclined = false;
  @tracked user;
  @tracked isRecording = false;
  @tracked roomSid;

  @reads('persistentProperties.userName') userName;
  @reads('persistentProperties.uuid') uuid;
  @reads('persistentProperties.noiseCancellationEnabled') noiseCancellationEnabled;
  @reads('roomModel.origin') origin;
  @reads('roomModel.roomId') roomId;
  @reads('roomModel.clinicianId') clinicianId;
  @reads('roomModel.featureThClinicianAuth') featureThClinicianAuth;
  @reads('roomModel.featureThChime') featureThChime;
  @reads('roomModel.hostJoinTime') hostJoinTime;
  @reads('appointmentSettings.waitingRoomEnabled') waitingRoomEnabled;
  @not('isHost') isGuest;
  @notEmpty('userData.id') isAuthenticated;

  @task(function* ({ userData, participantSid }) {
    if (!userData) return;

    yield waitForProperty(this, 'participants.[]', participants =>
      participants.findBy('identity', participantSid)
    );
    let sender = this.participants.findBy('identity', participantSid);
    let isHost = userData.id === this.roomModel.clinicianId;

    setProperties(sender, { userData, isHost });
  })
  setParticipantUserDataTask;

  setHostJoinTime(hostJoinTime) {
    if (!this.isHost) return;

    set(this.roomModel, 'hostJoinTime', hostJoinTime);
  }

  get pendingRequests() {
    return this.joinRequests.filter(({ status }) => status === JOIN_REQUEST_STATUSES.waiting);
  }

  @computed('user', 'userName', 'featureThChime')
  get userData() {
    if (this.user) return this.user;

    return this.featureThChime ? { name: this.userName } : null;
  }

  get pendingRequestsNumber() {
    return this.pendingRequests.length;
  }

  @computed('userData', 'clinicianId')
  get isHost() {
    return !!(this.userData?.id && this.userData.id === this.clinicianId);
  }

  @computed('origin')
  get isClient() {
    return this.origin === CLIENT_ORIGIN;
  }

  @computed('roomId')
  get signInUrl() {
    return `/users/sign_in?roomId=${this.roomId}`;
  }

  @computed('roomId')
  get switchAccountUrl() {
    return `/users/switch_accounts?roomId=${this.roomId}`;
  }

  get checkNewParticipantsIntervalMs() {
    if (isEmberTesting()) return 1;

    return this.joinRequests.length ? 15_000 : 10_000;
  }

  setInitialData(data) {
    let { settings, ...roomModel } = data;

    set(this, 'roomModel', roomModel);

    if (!settings) return;

    this.appointmentSettings.change(
      this.isHost
        ? settings
        : Object.fromEntries(
            Object.entries(settings).filter(([key]) => !HOST_SETTINGS.includes(key))
          )
    );
  }

  setUserName(userName) {
    this.persistentProperties.setProps({ userName });

    if (!this.user) return;

    setProperties(this.user, { name: userName });
  }

  setUserData(userData) {
    if (!this.persistentProperties.userName && userData.name) {
      this.persistentProperties.setProps({ userName: userData.name });
    }

    this.user = { ...userData, name: this.userName, clinicianName: userData.name };
  }

  setInWaitingRoom(inWaitingRoom) {
    if (this.isHost || !this.featureThClinicianAuth) return;

    set(this, 'inWaitingRoom', inWaitingRoom);
  }

  addParticipant(participant) {
    if (!this.participants.length) this.setFirstParticipantJoinTime(moment());
    this.participants.addObject(participant);
    this.maxParticipantsOnCall = Math.max(this.maxParticipantsOnCall, this.participants.length + 1);

    if (!this.isHost || this.featureThChime) return;
    this.remoteTracks.ensureParticipantHasTracks(participant);
  }

  removeParticipant(identity) {
    let participant = this.participants.findBy('identity', identity);
    this.participants.removeObject(participant);
  }

  admitAllPendingRequests() {
    this.pendingRequests.forEach((request, i) =>
      this.changeRequestStatus(request, JOIN_REQUEST_STATUSES.admitted, {
        // Fix for the Twilio iOS SDK bug
        sendingDelay: this.featureThChime ? 0 : i * ADMIT_DELAY_FACTOR_MS,
      })
    );
  }

  changeRequestStatus(request, status, options = {}) {
    if (status === request.status || !this.isHost) return;

    let { sendingDelay = 0 } = options;
    let eventType = SESSION_EVENT_TYPE_BY_STATUS[status];
    let sentToWaitingRoom = status === JOIN_REQUEST_STATUSES.waiting;

    this.#sendRequestStatusMessage({ eventType, receiverUuid: request.uuid, sendingDelay });

    this.joinRequests = this.joinRequests.map(joinRequest =>
      joinRequest === request ? { ...joinRequest, status, sentToWaitingRoom } : joinRequest
    );

    if (sentToWaitingRoom) return;

    if (!this.pendingRequestsNumber) {
      this.floatingUiElements.hideJoinRequestNotification();
    }
  }

  handleNewRequests(requests) {
    let { waitingRoomEnabled } = this.appointmentSettings;
    let hadPendingRequests = !!this.pendingRequestsNumber;

    this.#updateJoinRequestsList(requests);

    if (!this.pendingRequestsNumber) return;
    if (!waitingRoomEnabled) return this.admitAllPendingRequests();
    if (!this.joinRequestSoundPlayed) {
      this.soundNotification.playJoinRequestSound();
      set(this, 'joinRequestSoundPlayed', true);
    }
    if (!hadPendingRequests && this.floatingUiElements.joinRequestNotificationHidden) {
      this.floatingUiElements.showShortJoinRequestNotification();
    }
  }

  async handleJoinRequestDeclined() {
    this.joinRequestDeclined = true;
    if (isEmberTesting()) return;

    await waitForLater(1000);
    this.roomChannel.unsubscribe();
  }

  sendToWaitingRoom(participant) {
    let uuid = this.featureThChime ? participant.externalUserId : participant.uuid;

    let request = this.joinRequests.findBy('uuid', uuid);

    if (!request) {
      request = { uuid, name: participant.name };
      this.joinRequests.addObject(request);
    }

    this.#trackAdminAction(HOST_ADMIN_ACTIONS.sendToWaitingRoom);
    this.changeRequestStatus(request, JOIN_REQUEST_STATUSES.waiting);
  }

  showLoginNotification() {
    this.floatingUiElements.showEventNotification({
      text: `Signed in as ${this.userData.name}, ${this.userData.practiceName} Practice`,
      icon: 'check-circle',
    });
  }

  showSentToWaitingRoomNotification() {
    this.floatingUiElements.showEventNotification({ text: 'You’ve been sent to the waiting room' });
  }

  cancelJoinRequest({ uuid, connectionUuid }) {
    let request = connectionUuid
      ? this.joinRequests.findBy('connectionUuid', connectionUuid)
      : this.joinRequests.findBy('uuid', uuid);

    if (!request || request.status === JOIN_REQUEST_STATUSES.declined) return;
    this.remoteLogger.info('%j', { message: 'Cancelled join request', participant: uuid });
    this.joinRequests.removeObject(request);
    if (!this.pendingRequestsNumber) {
      this.floatingUiElements.hideJoinRequestNotification();
    }
  }

  resetRoomProperties() {
    this.joinRequests = [];
    this.joinRequestDeclined = false;
    this.isRecording = false;
    setProperties(this, {
      inWaitingRoom: false,
      participants: [],
      joinRequestSoundPlayed: false,
      firstParticipantJoinTime: null,
    });
  }

  @action
  shareUserData(receiverUuid) {
    if (!this.userData) return;

    let userData = this.featureThChime ? { ...this.userData, name: this.userName } : this.userData;

    this.#sendSessionMessage(
      { eventType: SESSION_EVENTS_TYPES.shareUserData, userData },
      receiverUuid
    );

    if (this.isHost && this.appointmentSettings.isDirty) {
      this.announceHostControlChange(this.appointmentSettings.participantPermissions);
    }
  }

  processEnding({ hostLeft } = { hostLeft: false }) {
    if (this.isHost) {
      this.roomChannel.addMessageHandler(ROOM_CHANNEL_MESSAGE_TYPES.session, properties => {
        let { message, participantSid: senderUuid } = properties;

        if (message?.eventType === SESSION_EVENTS_TYPES.ended && senderUuid === this.uuid) {
          this.#unsubscribeFromRoomChannel({ withDelay: true });
          this.roomChannel.removeMessageHandler(ROOM_CHANNEL_MESSAGE_TYPES.session);
        }
      });
      if (this.roomChannel.connected) {
        this.#sendSessionMessage({ eventType: SESSION_EVENTS_TYPES.ended });
      }
    } else {
      this.#unsubscribeFromRoomChannel({ withDelay: hostLeft });
    }
    this.floatingUiElements.hideRecordingNotification();
    this.mixpanel.track('call left', {
      'is_host': this.isHost,
      reason: hostLeft || this.isHost ? 'clinician_ended' : 'client_left',
    });
  }

  processStart() {
    this.soundNotification.handleStart();
    this.mixpanel.track('call joined', {
      'scheduled_time': this.roomModel.startTime?.toString(),
      'is_host': this.isHost,
      'noise_cancellation': this.noiseCancellationEnabled ? 'enabled' : 'disabled',
      ...(this.isHost && { 'waiting_room': this.waitingRoomEnabled ? 'enabled' : 'disabled' }),
    });
  }

  announceHostControlChange(changedControls) {
    if (!this.roomChannel.connected) return;

    this.joinRequests.forEach(request => {
      this.#sendSessionMessage(
        { eventType: SESSION_EVENTS_TYPES.hostControlsChanged, changedControls },
        request.uuid
      );
    });
  }

  notifyTwilioTrackSubscribed(track, participant) {
    if (!this.isHost) return;

    this.#sendSessionMessage(
      {
        eventType: SESSION_EVENTS_TYPES.trackSubscriptionConfirmed,
        trackName: track.name,
      },
      participant.uuid
    );
  }

  #sendSessionMessage(message, receiverUuid) {
    if (!this.roomChannel.connected) return;
    this.roomChannel.sendMessage(message, ROOM_CHANNEL_MESSAGE_TYPES.session, { receiverUuid });
  }

  setFirstParticipantJoinTime(firstParticipantJoinTime) {
    set(this, 'firstParticipantJoinTime', firstParticipantJoinTime);
  }
  unsubscribeAfterTimeoutTask = task(async () => {
    await timeout(1_000);
    this.roomChannel.unsubscribe();
  });

  #updateJoinRequestsList(newRequests) {
    let joinRequestsHash = Object.fromEntries(
      this.joinRequests.map(request => [request.uuid, request])
    );

    newRequests.forEach(request => {
      let participant = this.participants.findBy('identity', request.uuid);
      let existingRequest = joinRequestsHash[request.uuid];
      let { connectionUuid } = request;

      if (participant && !participant.reconnecting) return;
      if (existingRequest && connectionUuid && existingRequest.connectionUuid === connectionUuid)
        return;

      joinRequestsHash[request.uuid] = {
        ...request,
        status: JOIN_REQUEST_STATUSES.waiting,
        timestamp: Date.now(),
        sentToWaitingRoom: false,
      };
      this.remoteLogger.info('%j', { message: 'Received join request', participant: request.uuid });
    });
    this.joinRequests = Object.values(joinRequestsHash).sortBy('timestamp');
  }

  async #sendRequestStatusMessage({ eventType, receiverUuid, sendingDelay }) {
    await waitForLater(sendingDelay);
    this.#sendSessionMessage({ eventType }, receiverUuid);
  }

  #trackAdminAction(action) {
    this.mixpanel.track('host admin action', { action });
  }

  #unsubscribeFromRoomChannel({ withDelay = false } = {}) {
    if (!withDelay || isEmberTesting()) return this.roomChannel.unsubscribe();

    return this.unsubscribeAfterTimeoutTask.perform();
  }

  startRecording() {
    this.isRecording = true;
  }

  stopRecording() {
    this.isRecording = false;
  }
}
