import { fetchDynamicAppLink } from 'frontend/utils/api/fetch-data';
import { isNewIpad } from 'frontend/utils/detect-browser';
import { waitForLater } from 'ember-simplepractice/utils/waiters';
import ENV from 'frontend/config/environment';
import MobileDetect from 'mobile-detect';

export function getAppLink() {
  let md = new MobileDetect(navigator?.userAgent);
  return md.os() === 'iOS' || isNewIpad() ? ENV.iosMobileApp : ENV.androidMobileApp;
}

export async function redirectToApp({ roomId, origin } = {}) {
  if (typeof roomId === 'undefined') {
    _redirectToUrl(ENV.mobileAppScheme);
  } else {
    let dynamicLink = await fetchDynamicAppLink({ roomId, origin });
    if (dynamicLink) {
      _redirectToUrl(dynamicLink);
    } else {
      _redirectToUrl(ENV.mobileAppScheme + roomId);
      await waitForLater(2500);
      _redirectToUrl(getAppLink());
    }
  }
}

function _redirectToUrl(url) {
  window.location = url;
}
