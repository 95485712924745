import { information } from 'ember-simplepractice/utils/modals';
import { reads } from 'macro-decorators';
import { requestRecordingStart, requestRecordingStop } from 'frontend/utils/api/recording';
import { tracked } from '@glimmer/tracking';
import Service, { service } from '@ember/service';

const RECORDING_FAILED_MESSAGE = 'Failed to start recording';
const STOP_RECORDING_FAILED_MESSAGE = 'Failed to stop recording';

export default class ChimeRecordingService extends Service {
  @service mixpanel;
  @service session;
  @service errorHandling;

  @reads('session.roomModel.featureAiNotetakerV2') featureAiNotetakerV2;

  @tracked isPendingRequest = false;

  async startRecording(meetingId) {
    this.updateIsPendingRequest(true);

    let response = await requestRecordingStart({
      meetingId,
      featureAiNotetakerV2: this.featureAiNotetakerV2,
    });

    if (response.ok) return this.mixpanel.track('recording started');
    let cause = null;
    try {
      let { errors = [] } = await response.json();

      if (errors?.[0]?.code === 409) return;

      cause = errors?.[0];
    } catch (err) {
      cause = err;
    }
    this.updateIsPendingRequest(true);
    this.errorHandling.notifyError(new Error(RECORDING_FAILED_MESSAGE, { cause }));
    return information({
      title: 'Recording failed',
      text: RECORDING_FAILED_MESSAGE,
      confirmButtonText: 'Dismiss',
    });
  }

  async stopRecording(meetingId) {
    this.updateIsPendingRequest(true);
    let response = await requestRecordingStop({ meetingId });

    if (response.ok) return this.mixpanel.track('recording stopped');
    let cause = null;
    try {
      let { errors = [] } = await response.json();

      cause = errors?.[0];
    } catch (err) {
      cause = err;
    }
    this.errorHandling.notifyError(new Error(STOP_RECORDING_FAILED_MESSAGE, { cause }));
    return information({
      title: 'Recording stop failed',
      text: STOP_RECORDING_FAILED_MESSAGE,
      confirmButtonText: 'Dismiss',
    });
  }

  updateIsPendingRequest(isPendingRequest) {
    this.isPendingRequest = isPendingRequest;
  }
}
