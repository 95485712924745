import { ROOM_CHANNEL_MESSAGE_TYPES } from 'frontend/constants';
import { isEmberTesting } from 'ember-simplepractice/utils/is-testing';
import { service } from '@ember/service';
import { set, setProperties } from '@ember/object';
import { task, timeout, waitForProperty } from 'ember-concurrency';
import MessagingService from 'frontend/services/messaging';
import classic from 'ember-classic-decorator';
import generateUUID from 'ember-simplepractice/utils/generate-uuid';
import moment from 'moment-timezone';

export const WEBSOCKET_CHANNEL_NAME = 'RoomChannel';
export const MESSAGE_TTL_MS = 30_000; // 30 seconds
const RESCHEDULE_SENDING_PERIOD = 3000;
const CLEANUP_PERIOD = 5000;
const ENSURE_CONNECTION_TIMEOUT = 20_000;

/* eslint-disable-next-line @simplepractice/simplepractice/require-native-class-id */
@classic
export default class RoomChannelService extends MessagingService {
  @service errorHandling;
  @service actionCable;
  @service mixpanel;
  @service remoteLogger;

  channel;
  connected;
  unacknowledgedMessages = new Map();
  processedMessages = new Map();
  ensureConnectionTimeout = isEmberTesting() ? 5_000 : ENSURE_CONNECTION_TIMEOUT;
  #failureListeners = [];

  subscribe({ roomId, userName }) {
    let channel = this.actionCable.consumer.subscriptions.create(
      { channel: WEBSOCKET_CHANNEL_NAME, roomId, userName },
      {
        received: message => this.receiveMessage(JSON.parse(message)),
        rejected: () => {
          this.errorHandling.notifyError(new Error('Room channel connection rejected'));

          return this.errorHandling.roomDisconnectedError();
        },
        connected: () => {
          this.remoteLogger.info('Connected to room channel');
          set(this, 'connected', true);
          this.ensureConnectionEstablished.cancelAll();
        },
        disconnected: ({ willAttemptReconnect }) => {
          this.remoteLogger.info('Disconnected from room channel');

          if (willAttemptReconnect) {
            this.ensureConnectionEstablished.perform();
          }
        },
      }
    );
    this.remoteLogger.info('Created room channel subscription');
    setProperties(this, { channel });

    this.resendUnacknowledgedMessagesTask.perform();
    this.cleanupProcessedMessagesTask.perform();
    this.ensureConnectionEstablished.perform();
  }

  receiveMessage(messageObject) {
    let { id } = messageObject;

    if (messageObject.type === ROOM_CHANNEL_MESSAGE_TYPES.acknowledge) {
      this.unacknowledgedMessages.delete(id);
      return;
    }

    if (id) {
      this.channel.send({ type: ROOM_CHANNEL_MESSAGE_TYPES.acknowledge, id });

      if (this.processedMessages.has(id)) return;

      this.processedMessages.set(id, Date.now());
    }

    this.messageHandlers[messageObject.type]?.(messageObject);
  }

  sendMessage(message = {}, type = '', options = {}) {
    let messageObject = this.createMessage(message, type, { id: generateUUID(), ...options });

    this.unacknowledgedMessages.set(messageObject.id, messageObject);

    if (!this.connected) return;

    this.channel.send(messageObject);
  }

  unsubscribe() {
    this.channel?.consumer.disconnect();
    this.channel?.unsubscribe();
    this.remoteLogger.info('Unsubscribed from room channel');
    this.resendUnacknowledgedMessagesTask.cancelAll();
    this.cleanupProcessedMessagesTask.cancelAll();
    this.unacknowledgedMessages.clear();
    this.processedMessages.clear();
    this.ensureConnectionEstablished.cancelAll();
    this.#failureListeners = [];
    setProperties(this, { chanel: null, connected: false });
  }

  resendUnacknowledgedMessagesTask = task(async () => {
    await waitForProperty(this, 'connected', Boolean);
    while (!this.isDestroyed) {
      for (let [id, message] of this.unacknowledgedMessages.entries()) {
        this.channel?.send(message);

        if (moment().diff(message.timestamp) >= MESSAGE_TTL_MS) {
          this.unacknowledgedMessages.delete(id);
        }
      }

      if (isEmberTesting()) return;

      await timeout(RESCHEDULE_SENDING_PERIOD);
    }
  });

  cleanupProcessedMessagesTask = task(async () => {
    let currentTime = Date.now();

    while (!this.isDestroyed) {
      for (let [key, timestamp] of this.processedMessages.entries()) {
        if (currentTime - timestamp >= MESSAGE_TTL_MS) {
          this.processedMessages.delete(key);
        }
      }

      if (isEmberTesting()) return;

      await timeout(CLEANUP_PERIOD);
    }
  });

  ensureConnectionEstablished = task(async () => {
    await timeout(this.ensureConnectionTimeout);
    if (this.connected) return;

    this.remoteLogger.info('Failed to connect to room channel');
    this.errorHandling.notifyError(new Error('Connection to room channel was not established'));
    this.errorHandling.roomDisconnectedError();

    if (this.#failureListeners.length) return this.#publishConnectionFail();
  });

  subscribeToConnectionFail(callback) {
    this.#failureListeners.push(callback);
  }

  #publishConnectionFail() {
    this.#failureListeners.forEach(callback => callback());
  }
}
