import {
  CHIME_MEETING_SESSION_CONNECTION_TIMEOUT_MS,
  CHIME_MEETING_SESSION_RECONNECT_TIMEOUT_MS,
  CHIME_MEETING_STATUS,
} from 'frontend/constants/chime';
import { action } from '@ember/object';
import { equal, reads } from 'macro-decorators';
import { tracked } from '@glimmer/tracking';
import Service, { service } from '@ember/service';

export default class ChimeMeetingManagerService extends Service {
  @service('chime.sdk') sdkService;

  @tracked audioVideo;
  @tracked meetingStatus;
  @tracked localAttendeeId;
  @tracked isWebAudioEnabled;

  logger;
  deviceController;
  meetingSession;

  @equal('meetingStatus', CHIME_MEETING_STATUS.Succeeded) isConnected;
  @equal('meetingStatus', CHIME_MEETING_STATUS.Loading) isLoading;
  @equal('meetingStatus', CHIME_MEETING_STATUS.Left) isLeft;
  @reads('sdkService.sdk') chimeSdk;

  async initMeetingSession(configurationData) {
    let {
      MeetingSessionConfiguration,
      ConsoleLogger,
      LogLevel,
      DefaultDeviceController,
      DefaultMeetingSession,
      DefaultEventController,
      VoiceFocusDeviceTransformer,
    } = this.chimeSdk;
    let meetingSessionConfiguration = new MeetingSessionConfiguration(
      configurationData.meeting,
      configurationData.attendee
    );
    meetingSessionConfiguration.reconnectTimeoutMs = CHIME_MEETING_SESSION_RECONNECT_TIMEOUT_MS;
    meetingSessionConfiguration.connectionTimeoutMs = CHIME_MEETING_SESSION_CONNECTION_TIMEOUT_MS;

    this.logger ||= new ConsoleLogger('MeetingLogs', LogLevel.INFO);
    this.isWebAudioEnabled ??= await VoiceFocusDeviceTransformer.isSupported();
    this.deviceController ||= new DefaultDeviceController(this.logger, {
      enableWebAudio: this.isWebAudioEnabled,
    });
    let eventController = new DefaultEventController(meetingSessionConfiguration, this.logger);

    this.meetingSession = new DefaultMeetingSession(
      meetingSessionConfiguration,
      this.logger,
      this.deviceController,
      eventController
    );
    this.audioVideo = this.meetingSession.audioVideo;
    this.localAttendeeId = configurationData.attendee.AttendeeId;
    this.meetingStatus = CHIME_MEETING_STATUS.Loading;
  }

  @action
  audioVideoDidStart() {
    this.meetingStatus = CHIME_MEETING_STATUS.Succeeded;
  }

  @action
  audioVideoDidStop() {
    this.meetingStatus = CHIME_MEETING_STATUS.Left;
  }

  setupAudioVideoObservers(observers) {
    if (!this.audioVideo) return;

    this.audioVideo.addObserver(observers);
  }

  removeAudioVideoObservers(observers) {
    if (!this.audioVideoObservers) return;

    this.audioVideo?.removeObserver(observers);
  }

  start(options) {
    this.audioVideoObservers = {
      audioVideoDidStart: this.audioVideoDidStart,
      audioVideoDidStop: this.audioVideoDidStop,
    };
    this.setupAudioVideoObservers(this.audioVideoObservers);
    return this.audioVideo?.start(options);
  }

  @action
  leave() {
    this.meetingSession = null;

    if (!this.audioVideo) return;

    this.audioVideo.unbindAudioElement();
    this.audioVideo.stop();
    this.removeAudioVideoObservers(this.audioVideoObservers);
    this.audioVideoObservers = null;
    this.audioVideo = null;
  }
}
